import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import LoadingPage from "containers/common/LoadingPage";

export interface MainClientPageProps {}
export default function MainClientPage({}: MainClientPageProps) {
  const router = useRouter();
  const { status } = useSession();

  console.log(status);

  if (status === "authenticated") {
    router.push("/g");
  }
  if (status === "unauthenticated") {
    router.push("/home");
  }
  return (
    <LoadingPage isCoredot isCenter text="코어닷투데이에 접속하고 있어요" />
  );
}
