import { Loader, LoaderProps } from "@mantine/core";

export interface LoadingPageProps {
  size?: number | string;
  isCenter?: boolean;
  isCoredot?: boolean;
  text?: React.ReactNode;
  loaderProps?: LoaderProps;
  className?: string;
  divProps?: Omit<React.HTMLAttributes<HTMLDivElement>, "className">;
}

export default function LoadingPage({
  size,
  isCenter = false,
  isCoredot = false,
  className = "my-32",
  text = (
    <>
      정보를 불러오는 중이예요 <br />
      조금만 기다려주세요
    </>
  ),
  loaderProps,
  divProps,
}: LoadingPageProps) {
  return (
    <div
      className={
        isCenter
          ? `m-auto flex flex-col items-center justify-center gap-2 text-center text-sm ${className}`
          : `flex items-center gap-2 text-sm ${className}`
      }
      {...divProps}
    >
      {isCoredot ? (
        <>
          <img
            src="/images/favicon.png"
            width={size || "58px"}
            height={size || "58px"}
            alt="logo"
          />
          <Loader size={size || "xs"} variant="oval" />
        </>
      ) : (
        <Loader size={size || "xl"} {...loaderProps} />
      )}

      <span>{text}</span>
    </div>
  );
}
